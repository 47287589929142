<template>
    <main class="bg-gray-800 w-full h-screen relative flex justify-center">
        <HeaderComponent/>
        <section class="w-full h-screen p-[10px]">
            <p v-if="infoMessage" class="mt-4 bg-white shadow-4xl text-gray-800 flex flex-col justify-center items-center font-bold text-center p-[20px] w-[350px] max-w-[90%] left-[50%] translate-x-[-50%] absolute z-50 top-[70px] rounded-[10px]"> 
                <i class="fa-solid fa-circle-info"></i>
                <span>{{infoMessage}}</span>
            </p>
            <div v-if="SuccessMessage" class="mt-4 bg-green-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-[350px] max-w-[90%] md:left-[65%] left-[50%] translate-x-[-50%] absolute z-50 top-[70px] rounded-[10px]">
                {{ SuccessMessage }}
            </div>
            <div v-if="ErrorMessage" class="mt-4 bg-red-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-[350px] max-w-[90%] md:left-[65%] left-[50%] translate-x-[-50%] absolute z-50 top-[70px] rounded-[10px]">
                {{ ErrorMessage }}
            </div>
            <section id="parent" class="w-full h-full relative rounded-[20px] overflow-y-auto bg-gray-100">
                <div class="p-[15px] fixed bg-white z-[100000] rounded-t-[15px] w-[calc(100%-20px)] top-[10px] md:w-[calc(100%-275px)] border-b border-gray-400 flex justify-between items-center">
                    <h1 class="text-sm font-bold text-gray-700">Paramètres du compte</h1>
                    <div class="flex items-center justify-center">
                        <div class="bg-gray-50 flex justify-center items-center">
                            <div class="w-[35px] h-[35px] rounded-[50%] overflow-hidden flex justify-center items-center relative">
                                <img v-if="user.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mx-[5px]">{{user.nomCompagnie}}</h1>
                        </div>
                        <div class="w-[40px] h-[40px] ml-[15px] md:hidden"></div>
                    </div>
                </div>
                <div class="flex mt-[70px] w-full relative justify-center items-center flex-col p-[20px]">
                    <div id="parent" class="w-full mt-[25px] flex flex-col justify-center items-center relative">
                        <div class="w-full relative flex md:flex-row flex-col pb-[10px] justify-center items-start">
                            <div class="md:w-[220px] min-w-[220px] md:h-[calc(100vh-150px)] z-40 fixed w-[calc(100%-60px)] left-[50%] translate-x-[-50%] md:translate-x-0 md:left-[280px] bg-white rounded-[10px] shadow-9xl md:mx-[10px] my-[10px] md:my-0 md:py-[15px] px-[15px] md:px-0 flex flex-row md:flex-col justify-between md:justify-start">
                                <div @click="toggleProfile()" class="flex w-full cursor-pointer relative md:px-[20px] md:py-[15px] px-[10px] py-[20px] text-gray-600 items-center justify-center md:justify-start" :class="{'md:border-r-[5px] border-b-[5px] md:border-b-0 border-gray-800 bg-gray-800/10 text-gray-800 font-bold': isProfile}">
                                    <i class="fa-solid fa-user w-[20px] text-center"></i>
                                    <h1 class="text-sm ml-[10px] hidden md:flex">Paramètres du profil</h1>
                                </div>
                                <div @click="togglePassword()" class="flex w-full cursor-pointer relative md:px-[20px] md:py-[15px] px-[10px] py-[20px] text-gray-600 items-center justify-center md:justify-start" :class="{'md:border-r-[5px] border-b-[5px] md:border-b-0 border-gray-800 bg-gray-800/10 text-gray-800 font-bold': isPassword}">
                                    <i class="fa-solid fa-lock w-[20px] text-center"></i>
                                    <h1 class="text-sm ml-[10px] hidden md:flex">Mot de passe</h1>
                                </div>
                                <div @click="toggleNotifications()" class="flex w-full cursor-pointer relative md:px-[20px] md:py-[15px] px-[10px] py-[20px] text-gray-600 items-center justify-center md:justify-start" :class="{'md:border-r-[5px] border-b-[5px] md:border-b-0 border-gray-800 bg-gray-800/10 text-gray-800 font-bold': isNotifications}">
                                    <i class="fa-solid fa-bell w-[20px] text-center"></i>
                                    <h1 class="text-sm ml-[10px] hidden md:flex">Notifications</h1>
                                </div>
                                <div @click="toggleEmployee()" class="flex w-full cursor-pointer relative md:px-[20px] md:py-[15px] px-[10px] py-[20px] text-gray-600 items-center justify-center md:justify-start" :class="{'md:border-r-[5px] border-b-[5px] md:border-b-0 border-gray-800 bg-gray-800/10 text-gray-800 font-bold': isEmployee}">
                                    <i class="fa-solid fa-users w-[20px] text-center"></i>
                                    <h1 class="text-sm ml-[10px] hidden md:flex">Employés</h1>
                                </div>
                            </div>
                            <div id="parent" class="md:w-[calc(100%-260px)] md:h-[calc(100vh-150px)] h-[calc(100vh-200px)] w-full absolute md:right-0 mb-[20px] overflow-y-auto top-[40px] md:top-0 bg-white shadow-9xl md:mx-[10px] my-[10px] md:my-0 rounded-[10px] p-[20px]">
                                <form @submit.prevent="updateUser" v-if="isProfile" class="w-full relative flex flex-col items-start justify-start">
                                    <div v-if="!mergedImages.length" @click="triggerFileInput" class="w-full h-[150px] border border-dashed cursor-pointer border-gray-400 rounded-[10px] flex flex-col justify-center items-center">
                                        <i class="fa-solid fa-camera text-gray-400 text-4xl"></i>
                                        <h1 class="text-sm font-bold text-gray-400 text-center mt-[5px]">
                                            Ajouter des images <span class="text-red-500">*</span>
                                        </h1>
                                    </div>
                                    <div v-else class="w-full h-[150px] border border-dashed border-gray-400 rounded-[10px] flex flex-col justify-center items-center relative">
                                        <img :src="mergedImages[0].preview || mergedImages[0]" class="h-full w-full object-cover rounded-[10px]" />
                                        <div @click="removeImage(0)" class="absolute top-[5px] right-[5px] w-[20px] h-[20px] flex justify-center items-center bg-red-600 text-white rounded-full cursor-pointer">
                                            <i class="fa-solid fa-times"></i>
                                        </div>
                                    </div>
                                    <input type="file" ref="fileInput" @change="handleFileChange" multiple class="hidden">
                                    <div ref="scrollC" id="parent" class="w-full md:max-w-[620px] relative py-[10px] rounded-[10px] pl-[2px] flex justify-start items-center overflow-x-auto">
                                        <div v-for="(image, index) in mergedImages" :key="index" class="relative w-[80px] h-[80px] min-w-[80px] mr-[15px] min-h-[80px] cursor-pointer rounded-[10px] shadow-9xl flex justify-center items-center">
                                            <img :src="image.preview || image" class="h-full w-full object-cover rounded-[10px]" />
                                            <div @click="removeImage(index)" class="absolute top-[5px] right-[5px] w-[20px] h-[20px] flex justify-center items-center bg-red-600 text-white rounded-full cursor-pointer">
                                                <i class="fa-solid fa-times"></i>
                                            </div>
                                        </div>
                                        <div @click="triggerFileInput" class="w-[80px] h-[80px] min-w-[80px] mr-[15px] min-h-[80px] cursor-pointer rounded-[10px] shadow-9xl flex justify-center items-center">
                                            <i class="fa-solid fa-add text-3xl text-gray-600"></i>
                                        </div>
                                    </div>
                                    <div class="w-full relative my-[15px]">
                                        <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Nom de la compagnie</label>
                                        <input type="text" v-model="user.nomCompagnie" class="w-full p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                    </div>
                                    <div class="w-full relative flex md:flex-row flex-col md:justify-between">
                                        <div class="md:w-[48%] my-[15px] relative w-full">
                                            <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Nom du gérant</label>
                                            <input type="text" v-model="user.nomGerant" class="w-full p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                        </div>
                                        <div class="md:w-[48%] my-[15px] relative w-full">
                                            <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Prénoms du gérant</label>
                                            <input type="text" v-model="user.prenomsGerant" class="w-full p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                        </div>
                                    </div>
                                    <div class="w-full relative flex md:flex-row flex-col md:justify-between">
                                        <div class="md:w-[48%] my-[15px] relative w-full">
                                            <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Email du compte</label>
                                            <input type="text" readonly v-model="user.emailCompagnie" class="w-full bg-gray-100 p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm">
                                        </div>
                                        <div class="md:w-[48%] my-[15px] relative w-full">
                                            <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Email du gérant</label>
                                            <input type="text" v-model="user.emailGerant" class="w-full p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                        </div>
                                    </div>
                                    <div class="w-full relative my-[15px]">
                                        <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Numéro</label>
                                        <select name="" id="" class="text-sm p-[9px] absolute bottom-[2px] left-[1px] rounded-[5px]">
                                            <option value=""><i class="fa-flag fa-benin"></i>BJ</option>
                                            <option value=""><i class="fa-flag fa-benin"></i>BJ</option>
                                            <option value=""><i class="fa-flag fa-benin"></i>BJ</option>
                                            <option value=""><i class="fa-flag fa-benin"></i>BJ</option>
                                            <option value=""><i class="fa-flag fa-benin"></i>BJ</option>
                                        </select>
                                        <input type="text" v-model="user.numeroGerant" class="w-full p-[10px] rounded-[5px] outline-none border pl-[60px] border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                    </div>
                                    <div class="w-full relative flex md:flex-row flex-col md:justify-between">
                                        <div class="md:w-[48%] my-[15px] relative w-full">
                                            <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Adress de résidence</label>
                                            <input type="text" v-model="user.adressCompagnie" class="w-full p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                        </div>
                                        <div class="md:w-[48%] my-[15px] relative w-full">
                                            <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">ID du compte</label>
                                            <input type="text" readonly v-model="user.idCompte" class="w-full p-[10px] bg-gray-100 rounded-[5px] outline-none border border-gray-300 text-sm">
                                        </div>
                                    </div>
                                    <div class="w-full relative my-[15px]">
                                        <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Description</label>
                                        <textarea type="text" v-model="user.description" class="w-full h-[120px] resize-none p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10"></textarea>
                                    </div>
                                    <div class="w-full relative my-[15px]">
                                        <button type="submit" class="text-sm font-bold w-full md:w-auto text-white bg-gray-800 p-[10px] rounded-[5px]">Enregistrer les modifications</button>
                                    </div>
                                </form>
                                <form action="" @submit.prevent="UpdatePassword" v-if="isPassword" class="w-full relative flex flex-col items-start justify-start">
                                    <div class="w-full relative my-[15px]">
                                        <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Ancien Mot de passe</label>
                                        <input type="password" required v-model="oldPassword" class="w-full p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                    </div>
                                    <div class="w-full relative my-[15px]">
                                        <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Nouveau Mot de passe</label>
                                        <input type="password" required v-model="newPassword" class="w-full p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                    </div>
                                    <div class="w-full relative my-[15px]">
                                        <label for="" class="text-sm font-bold text-gray-800 mb-[5px]">Confirmer le nouveau Mot de passe</label>
                                        <input type="password" v-model="confirmPassword" class="w-full p-[10px] rounded-[5px] outline-none border border-gray-300 text-sm focus:border-blue-500 focus:bg-blue-200/10">
                                        <span v-if="notMatch" required class="text-xs font-semibold text-red-600"><i class="fa-solid fa-circle-info"></i> Les mots de passe ne correspondent pas</span>
                                    </div>
                                    <div class="w-full relative my-[15px]">
                                        <button type="submit" class="text-sm font-bold w-full md:w-auto text-white bg-gray-800 p-[10px] rounded-[5px]">Changer le Mot de passe</button>
                                    </div>
                                </form>
                                <div v-if="isNotifications" class="w-full relative flex flex-col">
                                    <div class="flex items-center justify-between my-[10px]">
                                        <h1 class="text-sm font-semibold text-gray-500">Recevoir des notifications par email ( Nouveautés, statistiques, astuce, ... )</h1>
                                        <button @click="toggleButton2" :class="{ 'bg-green-500': isButton2On, 'bg-gray-300': !isButton2On,}" class="relative ml-[10px] inline-flex items-center h-6 rounded-full w-11 min-w-11 transition-colors duration-300 ease-in-out focus:outline-none">
                                            <span :class="{'translate-x-5': isButton2On, 'translate-x-0': !isButton2On, }" class="inline-block w-5 min-w-5 h-5 transform bg-white rounded-full shadow-lg transition-transform duration-300 ease-in-out" ></span>
                                        </button>
                                    </div>
                                    <div class="flex items-center justify-between my-[10px]">
                                        <h1 class="text-sm font-semibold text-gray-500">Recevoir des notifications push sur votre appareil lorsqu'une réservation est faite ou lorsque vous recevez un nouveau message </h1>
                                        <button @click="toggleButton1" :class="{ 'bg-green-500': isButton1On, 'bg-gray-300': !isButton1On,}" class="relative ml-[10px] inline-flex items-center h-6 rounded-full w-11 min-w-11 transition-colors duration-300 ease-in-out focus:outline-none">
                                            <span :class="{ 'translate-x-5': isButton1On, 'translate-x-0': !isButton1On, }" class="inline-block w-5 min-w-5 h-5 transform bg-white rounded-full shadow-lg transition-transform duration-300 ease-in-out"></span>
                                        </button>
                                    </div>
                                </div>
                                <div v-if="isEmployee" class="w-full relative">
                                    <div class="w-full relative flex justify-between items-center">
                                        <div class="md:w-[300px] w-[170px] relative">
                                            <label for="" class="text-sm font-bold absolute left-[10px] top-[10px] text-gray-500"><i class="fa-solid fa-search"></i></label>
                                            <input type="text" class="w-full p-[10px] outline-none border border-gray-300 pl-[30px] rounded-[5px] text-sm font-semibold text-gray-500" placeholder="Recherchez un employé">
                                        </div>
                                        <button class="text-sm font-bold p-[10px] rounded-[5px] bg-gray-800 text-white">
                                            Ajouter
                                        </button>
                                    </div>
                                    <div class="mt-[20px] flex md:justify-start justify-center flex-wrap">
                                        <div class="md:w-[200px] w-full h-[200px] rounded-[10px] p-[10px] relative shadow-4xl m-[10px]">
                                            <div class="flex items-start pb-[10px] border-b border-gray-200 justify-between">
                                                <div class="flex items-start">
                                                    <div class="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                                        <img src="../assets/images/voyage1.jpg" alt="" class="absolute w-full h-full object-cover object-center">
                                                    </div>
                                                    <div class="ml-[5px]">
                                                        <h1 class="font-bold text-gray-500 text-xs">Mickaël AGOSSA</h1>
                                                        <p class="text-[10px] text-gray-400">agossamickael01@gmail.com</p>
                                                    </div>
                                                </div>
                                                <button><i class="fa-solid fa-ellipsis-vertical"></i></button>
                                            </div>
                                            <div class="flex flex-wrap mt-[10px]">
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    Sécrétaire
                                                </div>
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    Cotonou
                                                </div>
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    1206-2024-DTCG
                                                </div>
                                            </div>
                                            <div class="absolute bottom-0 left-0 right-0 flex justify-between items-center p-[10px] w-full">
                                                <button class="text-xs font-bold bg-green-600 w-full text-white rounded-[5px] text-center p-[10px]">Activé</button>
                                            </div>
                                        </div>
                                        <div class="md:w-[200px] w-full h-[200px] rounded-[10px] p-[10px] relative shadow-4xl m-[10px]">
                                            <div class="flex items-start pb-[10px] border-b border-gray-200 justify-between">
                                                <div class="flex items-start">
                                                    <div class="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                                        <img src="../assets/images/voyage1.jpg" alt="" class="absolute w-full h-full object-cover object-center">
                                                    </div>
                                                    <div class="ml-[5px]">
                                                        <h1 class="font-bold text-gray-500 text-xs">Mickaël AGOSSA</h1>
                                                        <p class="text-[10px] text-gray-400">agossamickael01@gmail.com</p>
                                                    </div>
                                                </div>
                                                <button><i class="fa-solid fa-ellipsis-vertical"></i></button>
                                            </div>
                                            <div class="flex flex-wrap mt-[10px]">
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    Sécrétaire
                                                </div>
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    Cotonou
                                                </div>
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    1206-2024-DTCG
                                                </div>
                                            </div>
                                            <div class="absolute bottom-0 left-0 right-0 flex justify-between items-center p-[10px] w-full">
                                                <button class="text-xs font-bold bg-red-500 text-white rounded-[5px] w-full text-center p-[10px]">Désactiver</button>
                                            </div>
                                        </div>
                                        <div class="md:w-[200px] w-full h-[200px] rounded-[10px] p-[10px] relative shadow-4xl m-[10px]">
                                            <div class="flex items-start pb-[10px] border-b border-gray-200 justify-between">
                                                <div class="flex items-start">
                                                    <div class="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                                        <img src="../assets/images/voyage1.jpg" alt="" class="absolute w-full h-full object-cover object-center">
                                                    </div>
                                                    <div class="ml-[5px]">
                                                        <h1 class="font-bold text-gray-500 text-xs">Mickaël AGOSSA</h1>
                                                        <p class="text-[10px] text-gray-400">agossamickael01@gmail.com</p>
                                                    </div>
                                                </div>
                                                <button><i class="fa-solid fa-ellipsis-vertical"></i></button>
                                            </div>
                                            <div class="flex flex-wrap mt-[10px]">
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    Sécrétaire
                                                </div>
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    Cotonou
                                                </div>
                                                <div class="p-[5px] text-center border m-[5px] rounded-[5px] border-gray-400 text-gray-400 text-sm">
                                                    1206-2024-DTCG
                                                </div>
                                            </div>
                                            <div class="absolute bottom-0 left-0 right-0 flex justify-between items-center p-[10px] w-full">
                                                <button class="text-xs font-bold bg-red-500 text-white rounded-[5px] w-full text-center p-[10px]">Désactiver</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import LineChart from "@/components/LineChart.vue"
import { ref, onMounted, watch, nextTick, onUpdated } from 'vue'
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';
import axios from 'axios'

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const newPassword = ref('')
const oldPassword = ref('')
const confirmPassword = ref('')
const notMatch = ref(false)
const UpdatePassword = async () => {
    if (newPassword.value !== confirmPassword.value) {
        notMatch.value = true
        return
    }
  try {
    const response = await axios.put('/api/user/update-password', { emailCompagnie: user.value.emailCompagnie, oldPassword: oldPassword.value, newPassword: newPassword.value });
    SuccessMessage.value = 'Votre mot de passe a été mis à jour avec succès'
    hideSuccessMessageAfterDelay()
    notMatch.value = false
    newPassword.value = ''
    oldPassword.value = ''
    confirmPassword.value = ''
  } catch (error) {
    ErrorMessage.value = 'Erreur lors de la mis à jour du mot de passe'
    hideErrorMessageAfterDelay()
    notMatch.value = false
    newPassword.value = ''
    oldPassword.value = ''
    confirmPassword.value = ''
  }
};

const isButton1On = ref(false)
const isButton2On = ref(false)
const infoMessage = ref('')
const SuccessMessage = ref('')
const ErrorMessage = ref('')

const hideInfoMessageAfterDelay = () => {
    setTimeout(() => {
      infoMessage.value = ''; 
    }, 5000);
};
const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      SuccessMessage.value = ''; 
    }, 3000);
};
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      ErrorMessage.value = ''; 
    }, 3000);
};

const toggleButton1 = () => {
    isButton1On.value = !isButton1On.value
    if (isButton1On.value) {
        infoMessage.value = 'Veillez installer cette application web sur votre appareil avant d\'activer cette fonctionnalité'
    } else {
        infoMessage.value = ''
    }
    hideInfoMessageAfterDelay()
}

const toggleButton2 = () => {
  isButton2On.value = !isButton2On.value
}

const isProfile = ref(true)
const isPassword = ref(false)
const isNotifications = ref(false)
const isEmployee = ref(false)

function toggleProfile() {
    isProfile.value = true
    isPassword.value = false
    isNotifications.value = false
    isEmployee.value = false
}
function togglePassword() {
    isProfile.value = false
    isPassword.value = true
    isNotifications.value = false
    isEmployee.value = false
}
function toggleNotifications() {
    isProfile.value = false
    isPassword.value = false
    isNotifications.value = true
    isEmployee.value = false
}
function toggleEmployee() {
    isProfile.value = false
    isPassword.value = false
    isNotifications.value = false
    isEmployee.value = true
}

const selectedImages = ref([]);

const fileInput = ref(null);

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileChange = (event) => {
  const files = event.target.files;
  selectedImages.value = Array.from(files).map(file => ({
    file,
    preview: URL.createObjectURL(file)
  }));
};
/* 
const handleFileChange = (event) => {
  const files = event.target.files;
  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.onload = (e) => {
      selectedImages.value.push({ preview: e.target.result });
    };
    reader.readAsDataURL(files[i]);
  }
}; */

const mergedImages = computed(() => {
  return [
    ...user.value.photos.map(url => ({ preview: url })),
    ...selectedImages.value
  ];
});

const removeImage = (index) => {
  selectedImages.value.splice(index, 1);
};

const scrollC = ref(null)

const scrollToBottom = () => {
    if (scrollC.value) {
        scrollC.value.scrollLeft = scrollC.value.scrollWidth;
    }
}

const updateUser = async () => {
  const formData = new FormData();
  formData.append('user', JSON.stringify(user.value));
  selectedImages.value.forEach((image, index) => {
    formData.append("photos", image.file);
  });

  try {
    const response = await axios.put(`/api/user/${user.value._id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    authStore.setUser(response.data);
    SuccessMessage.value = 'Vos informations ont été modifiées avec succès'
    hideSuccessMessageAfterDelay()
  } catch (error) {
    ErrorMessage.value = 'Erreur lors de la modification de vos informations'
    hideErrorMessageAfterDelay()
  }
};

watch(user, (newUser) => {
  if (newUser.photos) {
    selectedImages.value = newUser.photos.map(photo => ({
      file: null,
      preview: photo
    }));
  }
});

onUpdated(() => {
    scrollToBottom()
})

</script>

<style scoped>
#li_two:nth-child(even) {
    background-color: white;
}
#li_two:nth-child(odd) {
    background-color: rgb(243, 244, 246);
}
.Liste:hover h1 {
    color: #e5e7eb;
}
.Liste:hover p {
    color: #e5e7eb;
}
.Liste:nth-child(even) {
    border-style: dashed;
}
.hiverless_pere:hover .hiverless {
    display: flex;
}
.hiverless_pere .hiverless {
    display: none;
}
</style>
