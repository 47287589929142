<template>
    <main class="bg-gray-800 w-full h-screen relative flex justify-center">
        <HeaderComponent/>
        <section class="w-full h-screen p-[10px]">
            <section id="parent" class="w-full h-full relative rounded-[20px] overflow-y-auto bg-white">
                <div class="p-[15px] fixed bg-white z-[100000] rounded-t-[15px] w-[calc(100%-20px)] top-[10px] md:w-[calc(100%-275px)] border-b border-gray-400 flex justify-between items-center">
                    <h1 class="text-sm font-bold text-gray-700">Tableau de bord</h1>
                    <div class="flex items-center justify-center">
                        <div class="bg-gray-50 flex justify-center items-center">
                            <div class="w-[35px] h-[35px] rounded-[50%] overflow-hidden flex justify-center items-center relative">
                                <img v-if="user.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mx-[5px]">{{ user.nomCompagnie }}</h1>
                        </div>
                        <div class="w-[40px] h-[40px] ml-[15px] md:hidden"></div>
                    </div>
                </div>
                <div class="w-full mt-[70px] relative flex justify-center items-center md:flex-row flex-col p-[15px]">
                    <div class="md:w-[calc(100%/3-20px)] w-full h-[125px] rounded-[10px] shadow-4xl m-[10px] flex justify-start pl-[50px] items-center">
                        <div class="w-[60px] h-[60px] rounded-[50%] bg-blue-100 flex justify-center items-center text-blue-600 text-xl">
                            <i class="fa-solid fa-rectangle-list"></i>
                        </div>
                        <div class="flex flex-col ml-[10px]">
                            <h1 class="text-2xl font-bold text-gray-600">{{ numberOfReservationsToday }}</h1>
                            <span class="text-xs font-semibold text-gray-500">Nombre de réservation /j</span>
                        </div>
                    </div>
                    <div class="md:w-[calc(100%/3-20px)] w-full h-[125px] rounded-[10px] shadow-4xl m-[10px] flex justify-start pl-[50px] items-center">
                        <div class="w-[60px] h-[60px] rounded-[50%] bg-blue-100 flex justify-center items-center text-blue-600 text-xl">
                            <i class="fa-solid fa-book-open "></i>
                        </div>
                        <div class="flex flex-col ml-[10px]">
                            <h1 class="text-2xl font-bold text-gray-600">{{ reservations.length }}</h1>
                            <span class="text-xs font-semibold text-gray-500">Nombre de réservation total</span>
                        </div>
                    </div>
                    <div class="md:w-[calc(100%/3-20px)] w-full h-[125px] rounded-[10px] shadow-4xl m-[10px] flex justify-start pl-[50px] items-center">
                        <div class="w-[60px] h-[60px] rounded-[50%] bg-blue-100 flex justify-center items-center text-blue-600 text-xl">
                            <i class="fa-solid fa-location-dot "></i>
                        </div>
                        <div class="flex flex-col ml-[10px]">
                            <h1 class="text-2xl font-bold text-gray-600">{{ trajets.length }}</h1>
                            <span class="text-xs font-semibold text-gray-500">Nombre de Trajet</span>
                        </div>
                    </div>
                </div>
                <div class="w-full relative flex justify-center items-center md:flex-row flex-col p-[15px]">
                    <div class="md:w-[calc(70%-20px)] w-full h-[400px] rounded-[10px] shadow-4xl flex justify-center items-center flex-col relative m-[10px] bg-white">
                        <div class="flex w-full justify-between items-center px-[20px] py-[10px]">
                            <div class="flex flex-col">
                                <h1 class="font-bold text-sm md:text-lg text-gray-800">Traffic Général</h1>
                                <span class="mt-[5px] text-xs lg:text-sm text-gray-400">Toutes les périodes</span>
                            </div>
                            <div class="flex flex-col items-end">
                                <h1 class="font-bold text-sm md:text-lg text-gray-800">{{ totalReservations }}</h1>
                                <span class="mt-[5px] text-xs lg:text-sm text-gray-400">Last update {{ lastUpdate }}</span>
                            </div>
                        </div>
                        <LineChart :monthly-reservations="monthlyReservations"/>
                    </div>
                    <div class="md:w-[calc(30%-20px)] w-full h-[400px] rounded-[10px] shadow-4xl relative m-[10px] bg-gray-800 p-[20px] flex justify-start items-center flex-col">
                        <div class="w-full flex justify-between items-end">
                            <div class="flex flex-col">
                                <span class="uppercase text-xs font-bold text-gray-300">DE</span>
                                <h1 class="text-sm font-bold text-gray-100 ">{{ randomTrip.depart }}</h1>
                            </div>
                            <span class="text-[10px] text-gray-300">-</span>
                            <div class="flex flex-col">
                                <span class="uppercase text-xs font-bold text-gray-300">À</span>
                                <h1 class="text-sm font-bold text-gray-100 ">{{ randomTrip.destination }}</h1>
                            </div>
                        </div>
                        <div class="my-[20px] h-[200px] bg-gray-700 w-full relative">
                            <!-- Placeholder for additional content, such as an image or map -->
                            <img v-if="randomTrip.destination == 'Parakou'" src="../assets/images/parakou.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="randomTrip.destination == 'Natitingou'" src="../assets/images/nati.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="randomTrip.destination == 'Porto Novo'" src="../assets/images/porto-novo.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="randomTrip.destination == 'Bantè'" src="../assets/images/bante.jpeg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="randomTrip.destination == 'Grand Popo'" src="../assets/images/grand-popo.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="randomTrip.destination == 'Ouidah'" src="../assets/images/ouidah.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="randomTrip.destination == 'Calavi' || randomTrip.destination == 'Abomey Calavi'" src="../assets/images/calavi.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else-if="randomTrip.destination == 'Bohicon'" src="../assets/images/bohicon.jpg" alt="" class="w-full h-full absolute object-cover">
                            <img v-else src="../assets/images/indisponible.jpg" alt="" class="w-full h-full absolute object-cover">
                        </div>
                        <div class="w-full relative flex justify-start items-center">
                            <div class="text-xs text-cente font-semibold py-[5px] px-[20px] rounded-[20px] cursor-pointer bg-blue-600 text-white">Prix</div>
                            <div class="text-xs text-cente font-semibold py-[5px] px-[20px] rounded-[20px] cursor-pointer ml-[15px] bg-blue-100 text-black">Durée</div>
                        </div>
                        <div class="w-full flex items-center">
                            <h1 class="text-sm font-bold my-[10px] text-teal-200">{{ randomTrip.prix_reduit }}</h1>
                            <span class="mx-[5px] text-xl font-bold text-white">-</span>
                            <h1 class="text-sm font-bold my-[10px] text-teal-200">{{ randomTrip.duree }} heures</h1>
                        </div>
                    </div>
                </div>
                <div class="w-full relative bg-gray-50 flex flex-col justify-center items-center p-[15px]">
                    <div class="mx-[20px] my-[10px] w-full relative flex md:justify-between justify-center md:items-center items-start md:flex-row flex-col">
                        <h1 class="text-gray-800 text-sm font-bold my-[10px] ">Activités Récentes</h1>
                        <div class="flex justify-center items-center my-[10px]">
                            <div class="w-full max-w-[500px] md:w-[350px] relative flex">
                                <label for="" class="text-sm font-bold text-gray-800 absolute left-[10px] z-20 top-[10px]"><i class="fa-solid fa-search"></i></label>
                                <input type="text" placeholder="Rechercher une réservation" class="w-full text-sm relative p-[10px] rounded-[5px] pl-[30px] bg-gray-200 outline-none">
                            </div>
                            <div class="relative ml-[10px] flex justify-center items-center">
                                <label for="" class="text-sm font-bold text-gray-800 ">Dernière semaine</label>
                                <input type="checkbox" checked class="ml-[5px] mt-[2px]">
                            </div>
                        </div>
                    </div>
                    <div class="mx-[20px] my-[10px] w-full relative flex justify-center items-center">
                        <ul class="w-full relative">
                            <li class="flex justify-center items-center w-full relative border-b-[0.5px] border-gray-400 p-[10px]">
                                <div class="md:w-[70px] hidden md:flex font-bold text-sm text-gray-800">#ID</div>
                                <div class="w-[80px] font-bold text-sm text-gray-800">Date</div>
                                <div class="md:w-[calc(100%-280px)] w-[calc(100%-210px)] font-bold text-sm text-gray-800">Nom complet</div>
                                <div class="w-[80px] font-bold text-sm text-gray-800">Numero</div>
                                <div class="w-[50px] font-bold text-sm text-gray-800">Status</div>
                            </li>
                            <li id="li_two" v-for="(reservation, index) in reservations" :key="index" class="flex justify-center items-center w-full relative p-[10px] md:py-[20px]">
                                <div class="md:w-[70px] hidden md:flex font-bold text-sm text-gray-800"># {{ reservation.idReservation }}</div>
                                <div class="w-[80px] font-semibold text-sm text-gray-800">{{ reservation.date }}</div>
                                <div class="md:w-[calc(100%-280px)] w-[calc(100%-210px)] font-semibold text-xs text-gray-800">{{ reservation.prenoms }} {{ reservation.nom }}</div>
                                <div class="w-[80px] font-semibold text-sm text-gray-800">{{ reservation.numero }}></div>
                                <div class="w-[50px] font-semibold text-sm text-gray-800"><div class="w-[30px] h-[30px] rounded-[50%]" :class="{'bg-red-600': !reservation.isActive, 'bg-green-600': reservation.isActive}"></div></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import LineChart from "@/components/LineChart.vue"
import { onMounted, ref } from 'vue'
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';
import axios from 'axios'

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const reservationsM = ref([])
const trajets = ref([])
const numberOfReservationsToday = ref(0)
const ErrorMessage = ref('')
const reservations = ref([]);
const monthlyReservations = ref(Array(12).fill(0)); // Initialise un tableau pour stocker les réservations par mois
const totalReservations = ref(0);
const lastUpdate = ref('');

// Fonction pour récupérer les réservations
const fetchReservationsM = async () => {
  try {
    const response = await axios.get(`/api/reservations/compagnie/${user.value.idCompte}`);
    const allReservationsM = response.data;

    // Regrouper les réservations par mois
    allReservationsM.forEach(reservation => {
      const month = new Date(reservation.date).getMonth(); // Obtenir le mois (0-11)
      monthlyReservations.value[month] += 1; // Incrémenter le compteur pour le mois correspondant
    });

    totalReservations.value = allReservationsM.length;
    lastUpdate.value = new Date().toLocaleDateString('fr-FR'); // Mettre à jour la date de la dernière mise à jour

    reservationsM.value = allReservationsM;
  } catch (err) {
    console.error("Erreur lors de la récupération des réservations", err);
  }
};



const fetchReservations = async () => {
  try {
    const response = await axios.get(`/api/reservations/compagnie/${user.value.idCompte}`);
    const allReservations = response.data;

    // Obtenir la date d'aujourd'hui en format "dd/MM/yyyy"
    const today = new Date();
    const formattedToday = today.toLocaleDateString('fr-FR'); // Format "dd/MM/yyyy"

    // Filtrer les réservations pour aujourd'hui
    const todayReservations = allReservations.filter(reservation => reservation.date === formattedToday);

    // Compter le nombre de réservations pour aujourd'hui
    const numberOfTodayReservations = todayReservations.length;

    // Stocker les réservations et le nombre de réservations pour aujourd'hui
    reservations.value = allReservations;
    numberOfReservationsToday.value = numberOfTodayReservations;
  } catch (err) {
    ErrorMessage.value = "Erreur lors de la récupération des réservations";
  }
};

const randomTrip = ref({});

const fetchTrajets = async () => {
  try {
    const response = await axios.get(`/api/trajets/compagnie/${user.value.idCompte}`);
    trajets.value = response.data.map(trajet => ({ ...trajet, action: false }));

    // Sélectionner un trajet aléatoire initialement
    selectRandomTrip();
  } catch (err) {
    console.error("Erreur lors de la récupération des trajets", err);
  }
};

const selectRandomTrip = () => {
  if (trajets.value.length > 0) {
    const randomIndex = Math.floor(Math.random() * trajets.value.length);
    randomTrip.value = trajets.value[randomIndex];
  }
};

onMounted(() => {
    fetchReservations()
    fetchTrajets()
    fetchReservationsM()
})

</script>

<style scoped>
#li_two:nth-child(even) {
    background-color: white;
}
#li_two:nth-child(odd) {
    background-color: rgb(243, 244, 246);
}
</style>
