import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '../store/auth';
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ReservationView from '@/views/ReservationView.vue'
import ClientsView from '@/views/ClientsView.vue'
import MessageView from '@/views/MessageView.vue'
import SettingsView from '@/views/SettingsView.vue'
import CompagnieView from '@/views/CompagnieView.vue'
import scanneView from '@/views/scanneView.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: ReservationView,
    meta: { requiresAuth: true },
  },
  {
    path: '/clients',
    name: 'clients',
    component: ClientsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/messages',
    name: 'messages',
    component: MessageView,
    meta: { requiresAuth: true },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/compagnie',
    name: 'compagnie',
    component: CompagnieView,
    meta: { requiresAuth: true },
  },
  {
    path: '/scanner-qr',
    name: 'scanne',
    component: scanneView,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.token) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router
