// stores/auth.ts
import { defineStore } from 'pinia';
import piniaPersistedState from 'pinia-plugin-persistedstate';

interface User {
  emailGerant?: string;
  logo?: string;
  nomCompagnie: string;
  photos?: string[];
  nomGerant?: string;
  prenomsGerant?: string;
  emailCompagnie: string;
  numeroGerant?: number;
  numeroCompagnie: number;
  adressCompagnie: string;
  idCompte: string;
  description?: string;
  isSuper?: boolean;
}

interface AuthState {
  token: string | null;
  user: User | null;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    token: null,
    user: null,
  }),
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    setToken(token: string) {
      this.token = token;
    },
    logout() {
      this.token = null;
      this.user = null;
      sessionStorage.removeItem('Azerty234569NHyc'); // Assurez-vous de supprimer la clé persistante du sessionStorage
    },
  },
  persist: {
    // Utilisation de la sessionStorage comme alternative au localStorage
    key: 'Azerty234569NHyc',
    storage: sessionStorage,
  },
});
