<template>
    <main class="bg-gray-800 w-full h-screen relative flex justify-center">
        <HeaderComponent/>
        <section class="w-full h-screen p-[10px]">
            <section id="parent" class="w-full h-full relative rounded-[20px] overflow-y-auto bg-white">
                <div class="p-[15px] fixed bg-white z-[100000] rounded-t-[15px] w-[calc(100%-20px)] top-[10px] md:w-[calc(100%-275px)] border-b border-gray-400 flex justify-between items-center">
                    <h1 class="text-sm font-bold text-gray-700">Mes Messages</h1>
                    <div class="flex items-center justify-center">
                        <div class="bg-gray-50 flex justify-center items-center">
                            <div class="w-[35px] h-[35px] rounded-[50%] overflow-hidden flex justify-center items-center relative">
                                <img v-if="user.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mx-[5px]">{{ user.nomCompagnie }}</h1>
                        </div>
                        <div class="w-[40px] h-[40px] ml-[15px] md:hidden"></div>
                    </div>
                </div>
                <div class="flex mt-[70px] w-full relative justify-center items-center flex-col md:p-[20px]">
                    <div class="flex w-full relative justify-center items-center">
                        <div :class="{'hidden': isNOpen, 'flex': !isNOpen}" class="md:w-[30%] w-full md:flex relative md:h-[calc(100vh-130px)] h-[calc(100vh-90px)] bg-gray-100 md:mx-[10px]">
                            <div class="w-full z-30 absolute">
                                <label for="" class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-800"><i class="fa-solid fa-search"></i></label>
                                <input type="text" class="w-full outline-none p-[10px] bg-gray-200 text-sm text-gray-500 font-semibold" placeholder="Recherchez...">
                            </div>
                            <div id="parent" class="mt-[40px] h-[calc(100%-40px)] overflow-y-auto pb-[50px]">
                                <div @click="toggleNOpen()" class="flex cursor-pointer w-full bg-white relative p-[10px] my-[5px] justify-start items-center">
                                    <div class="w-[40px] h-[40px] min-w-[40px] min-h-[40px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                        <img src="../assets/images/voyage1.jpg" alt="" class="absolute w-full h-full object-cover object-center">
                                    </div>
                                    <div class="flex flex-col w-full relative ml-[10px]">
                                        <div class="w-full flex relative justify-between items-center text-xs font-bold">
                                            <h1 class="text-gray-800">Mickaël AGOSSA</h1>
                                            <span class="uppercase text-gray-400">12:58PM</span>
                                        </div>
                                        <p class="mt-[2.5px] text-gray-400 text-xs clamp">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi minima officia fugit esse amet suscipit officiis! Nihil ut atque explicabo itaque unde at dolorum alias rem, est fugiat sed assumenda.
                                        </p>
                                    </div>
                                </div>
                                <div @click="toggleNOpen()" class="flex cursor-pointer w-full bg-white relative p-[10px] my-[5px] justify-start items-center">
                                    <div class="w-[40px] h-[40px] min-w-[40px] min-h-[40px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                        <img src="../assets/images/voyage1.jpg" alt="" class="absolute w-full h-full object-cover object-center">
                                    </div>
                                    <div class="flex flex-col w-full relative ml-[10px]">
                                        <div class="w-full flex relative justify-between items-center text-xs font-bold">
                                            <h1 class="text-gray-800">Mickaël AGOSSA</h1>
                                            <span class="uppercase text-gray-400">12:58PM</span>
                                        </div>
                                        <p class="mt-[2.5px] text-gray-400 text-xs clamp">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi minima officia fugit esse amet suscipit officiis! Nihil ut atque explicabo itaque unde at dolorum alias rem, est fugiat sed assumenda.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="{'hidden': !isNOpen, 'flex': isNOpen}" class="md:w-[70%] w-full relative md:h-[calc(100vh-130px)] h-[calc(100vh-90px)] md:flex justify-center bg-gray-100 md:mx-[10px]">
                            <div class="w-full max-w-[550px] relative h-full p-[10px]">
                                <div class="w-full flex justify-start items-center pb-[10px] border-b border-gray-300 ">
                                    <div class="mr-[10px] md:hidden">
                                        <button class="text-sm" @click="toggleNOpen()">
                                            <i class="fa-solid fa-arrow-left"></i>
                                        </button>
                                    </div>
                                    <div class="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                        <img src="../assets/images/voyage1.jpg" alt="" class="absolute w-full h-full object-cover object-center">
                                    </div>
                                    <div class="flex flex-col w-full relative ml-[10px]">
                                        <div class="w-full flex relative justify-between items-center text-xs font-bold">
                                            <h1 class="text-gray-800">Mickaël AGOSSA</h1>
                                            <div class="uppercase text-gray-800 text-sm ">
                                                <button> <i class="fa-solid fa-gear"></i></button>
                                            </div>
                                        </div>
                                        <p class="text-gray-400 text-[9px] font-bold clamp">
                                            En ligne
                                        </p>
                                    </div>
                                </div>
                                <div ref="scrollC" class="w-full h-[calc(100%-100px)] relative py-[25px] overflow-y-auto">
                                    <div class="w-full relative my-[15px] flex justify-start items-end">
                                        <div class="w-[25px] h-[25px] min-w-[25px] min-h-[25px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                            <img src="../assets/images/voyage1.jpg" alt="" class="absolute w-full h-full object-cover object-center">
                                        </div>
                                        <div class="w-auto md:max-w-[250px] max-w-[70%] p-[15px] rounded-[15px] rounded-bl-none bg-blue-100 text-sm text-gray-600 ml-[15px]">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit ad hic, aperiam odio debitis voluptas dicta exercitationem? Incidunt iure voluptatem tenetur, nihil corporis sit praesentium officiis nemo officia, laudantium culpa.
                                        </div>
                                        <span class="text-xs font-bold text-gray-400 ml-[15px]">14:23</span>
                                    </div>
                                    <div class="w-full relative my-[15px] flex flex-row-reverse justify-start items-end">
                                        <div class="w-[25px] h-[25px] min-w-[25px] min-h-[25px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                            <img src="../assets/images/footer.jpg" alt="" class="absolute w-full h-full object-contain object-center">
                                        </div>
                                        <div class="w-auto md:max-w-[250px] max-w-[70%] p-[15px] rounded-[15px] rounded-br-none bg-gray-200 text-sm text-gray-600 mr-[15px]">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit ad hic, aperiam odio debitis voluptas dicta exercitationem? Incidunt iure voluptatem tenetur, nihil corporis sit praesentium officiis nemo officia, laudantium culpa.
                                        </div>
                                        <span class="text-xs font-bold text-gray-400 mr-[15px]">14:23</span>
                                    </div>
                                    <div class="w-full relative my-[15px] flex justify-start items-end">
                                        <div class="w-[25px] h-[25px] min-w-[25px] min-h-[25px] rounded-[50%] overflow-hidden flex relative justify-center items-center">
                                            <img src="../assets/images/voyage1.jpg" alt="" class="absolute w-full h-full object-cover object-center">
                                        </div>
                                        <div class="w-auto md:max-w-[250px] max-w-[70%] p-[15px] rounded-[15px] rounded-bl-none bg-blue-100 text-sm text-gray-600 ml-[15px]">
                                            Merci beaucoup !!
                                        </div>
                                        <span class="text-xs font-bold text-gray-400 ml-[15px]">14:23</span>
                                    </div>
                                </div>
                                <div class="absolute flex bottom-[10px] left-[10px] right-[10px] w-[calc(100%-20px)]">
                                    <input type="text" class="w-full p-[10px] py-[15px] rounded-[10px] pr-[100px] outline-none border border-gray-200 text-sm font-semibold placeholder:text-gray-500 text-gray-800 bg-transparent" placeholder="Ecrivez un message">
                                    <div class="absolute py-[5px] top-[10px] md:right-[90px] right-[50px]">
                                        <button class="text-sm font-semibold mx-[5px] text-gray-400">
                                            <i class="fa-solid fa-smile"></i>
                                        </button>
                                        <button class="text-sm font-semibold mx-[5px] text-gray-400">
                                            <i class="fa-solid fa-paperclip"></i>
                                        </button>
                                    </div>
                                    <button type="submit" class="py-[5px] px-[10px] rounded-[5px] bg-gray-800 cursor-pointer text-white font-bold text-sm absolute top-[10px] right-[10px]"><span class="md:flex hidden">Envoyer</span> <span class="md:hidden"><i class="fa-solid fa-paper-plane"></i></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import LineChart from "@/components/LineChart.vue"
import { ref, onMounted, nextTick } from 'vue'
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const isNOpen = ref(false)

function toggleNOpen() {
    isNOpen.value = !isNOpen.value
    nextTick(() => {
        scrollToBottom()
    })
}

const scrollC = ref(null)

const scrollToBottom = () => {
    if (scrollC.value) {
        scrollC.value.scrollTop = scrollC.value.scrollHeight;
    }
}

onMounted(() => {
    scrollToBottom()
})

</script>

<style scoped>
#li_two:nth-child(even) {
    background-color: white;
}
#li_two:nth-child(odd) {
    background-color: rgb(243, 244, 246);
}
.Liste:hover h1 {
    color: #e5e7eb;
}
.Liste:hover p {
    color: #e5e7eb;
}
.Liste:nth-child(even) {
    border-style: dashed;
}
.hiverless_pere:hover .hiverless {
    display: flex;
}
.hiverless_pere .hiverless {
    display: none;
}
.clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
