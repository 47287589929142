<template>
    <section class="flex w-full h-screen justify-center items-center md:flex-row flex-col-reverse relative">
        <div v-if="ErrorMessage" class="mt-4 bg-red-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-[350px] max-w-[90%] left-[50%] translate-x-[-50%] absolute z-50 top-[20px] rounded-[10px]">
            {{ ErrorMessage }}
        </div>
        <div class="md:w-6/12 w-full h-full bg-white flex justify-center items-center flex-col">
            <div class="logo flex items-center text-sm font-bold text-black">
                <div class="w-[25px] h-[25px] flex justify-center items-center bg-gray-800 text-white mr-[10px]">
                    <i class="fa-solid fa-bus-simple text-sm"></i>
                </div>
                <h1>Bénin Ease Travel</h1>
            </div>
            <h1 class="my-[20px] text-3xl font-bold text-gray-800 text-center">Bon retour !</h1>
            <form @submit.prevent="login" class="max-w-[400px] w-full px-[20px] relative">
                <div class="my-[15px] w-full relative">
                    <label for="email" class="text-sm font-bold flex text-gray-700 mb-[5px]">Email <span class="text-red-700">*</span></label>
                    <input type="text" v-model="email" name="email" id="email" placeholder="compagnie@bet-booking.com" class="p-[10px] rounded-[5px] border-[0.5px] border-gray-800 outline-none text-sm w-full" required>
                </div>
                <div class="my-[15px] w-full relative">
                    <label for="password" class="text-sm font-bold flex text-gray-700 mb-[5px]">Mot de passe <span class="text-red-700">*</span></label>
                    <input type="password" v-model="password" name="password" id="password" placeholder="12345678@bet-booking.com" class="p-[10px] rounded-[5px] border-[0.5px] border-gray-800 outline-none text-sm w-full" required>
                </div>
                <div class="my-[10px] w-full relative flex justify-between items-center">
                    <div class="flex items-center">
                        <input type="checkbox" name="" id="" class="mr-[5px]">
                        <label for="" class="text-sm font-bold flex text-gray-700">Garder ma session ouverte</label>
                    </div>
                    <button class="text-sm hover:font-bold duration-300 text-gray-600 hover:text-gray-800 hover:underline">
                        Mot de passe ?
                    </button>
                </div>
                <div class="my-[40px] w-full relative">
                    <button type="submit" class="p-[10px] text-sm font-bold text-white bg-gray-800 w-full rounded-[5px] ">Se connecter</button>
                </div>
            </form>
        </div>
        <div class="md:w-6/12 w-full h-full hidden md:flex overflow-hidden relative">
            <div class="absolute top-0 h-full w-full z-[-1]">
                <img src="../assets/images/footer.png" alt="" class="w-full h-full object-cover md:object-right object-left-top absolute">
            </div>
            <div class="absolute top-[40px] right-[40px] flex justify-center items-end flex-col">
                <div class="logo flex items-center text-sm font-bold text-black">
                    <div class="w-[25px] h-[25px] flex justify-center items-center bg-gray-800 text-white mr-[10px]">
                        <i class="fa-solid fa-bus-simple text-sm"></i>
                    </div>
                    <h1>Bénin Ease Travel</h1>
                </div>
                <p class="text-gray-500 text-right w-[310px] my-[15px]">
                    Votre meilleur partenaire dans le domaine des transports en commun en bus.
                </p>
                <button class="p-[10px] px-[25px] border-gray-800 border rounded-[5px] text-sm font-bold text-gray-800">Nous contacter</button>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useAuthStore } from '@/store/auth';
import axios from 'axios';
import { useRouter } from 'vue-router'
import { ref } from 'vue'

const email = ref('')
const password = ref('')

const router = useRouter()

const ErrorMessage = ref('')

const authStore = useAuthStore();
const login = async () => {
  try {
    const response = await axios.post('/api/user/login', { emailCompagnie: email.value, password: password.value });
    authStore.setToken(response.data.token);
    authStore.setUser(response.data.user);
    router.push("/dashboard")
  } catch (error) {
    ErrorMessage.value = 'Email ou mot de passe incorrect'
    hideErrorMessageAfterDelay()
  }
};

const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      ErrorMessage.value = ''; 
    }, 3000);
};
</script>

<style>

</style>