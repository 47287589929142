<template>
    <main class="bg-gray-800 w-full h-screen relative flex justify-center">
        <HeaderComponent/>
        <section class="w-full h-screen p-[10px]">
            <div v-if="SuccessMessage" class="mt-4 bg-green-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-[350px] max-w-[90%] md:left-[65%] left-[50%] translate-x-[-50%] absolute z-50 top-[70px] rounded-[10px]">
                {{ SuccessMessage }}
            </div>
            <div v-if="ErrorMessage" class="mt-4 bg-red-600 shadow-4xl text-white flex flex-col justify-center items-center font-bold text-center p-[20px] w-[350px] max-w-[90%] md:left-[65%] left-[50%] translate-x-[-50%] absolute z-50 top-[70px] rounded-[10px]">
                {{ ErrorMessage }}
            </div>
            <section id="parent" class="w-full h-full relative rounded-[20px] overflow-y-auto bg-white">
                <div class="p-[15px] fixed bg-white z-[100000] rounded-t-[15px] w-[calc(100%-20px)] top-[10px] md:w-[calc(100%-275px)] border-b border-gray-400 flex justify-between items-center">
                    <h1 class="text-sm font-bold text-gray-700">Réservation</h1>
                    <div class="flex items-center justify-center">
                        <div class="bg-gray-50 flex justify-center items-center">
                            <div class="w-[35px] h-[35px] rounded-[50%] overflow-hidden flex justify-center items-center relative">
                                <img v-if="user.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mx-[5px]">{{ user.nomCompagnie }}</h1>
                        </div>
                        <div class="w-[40px] h-[40px] ml-[15px] md:hidden"></div>
                    </div>
                </div>
                <div class="flex mt-[70px] w-full relative justify-center items-center flex-col p-[20px]">
                    <div class="w-full relative flex justify-between items-center">
                        <h1 class="text-sm font-bold text-gray-800">Liste des trajets</h1>
                        <button @click="toggleAjout()" class="text-sm font-bold py-[5px] px-[10px] text-gray-800 border border-gray-800 rounded-[5px]">Ajouter</button>
                    </div>
                    <div id="parent" class="w-full flex justify-start items-center relative mt-[25px]">
                        <!-- Container for the trajets -->
                        <div class="w-full h-full flex flex-wrap justify-between md:justify-start items-start">
                            <!-- Check if there are no trajets and show a message -->
                            <div v-if="trajets.length === 0 && !error" class="text-gray-500 text-sm font-bold flex flex-col justify-center items-center w-full relative max-w-[calc(100vw-60px)]">Il n'y a pas de trajets disponibles.</div>
                            <!-- Check if there is an error and show an error message with retry button -->
                            <div v-if="error" class="text-red-500 text-sm font-bold flex flex-col justify-center items-center w-full relative max-w-[calc(100vw-60px)]">
                                <p>Erreur lors de la récupération des trajets.</p>
                                <button @click="fetchTrajets" class="bg-blue-500 text-white px-4 py-2 rounded">Recharger</button>
                            </div>
                            <!-- Iterate over the trajets and display each one -->
                            <div @click="toggleAction(trajet)" v-for="(trajet, index) in trajets" :key="index" class="md:w-[120px] w-[calc(50%-10px)] h-[100px] p-[10px] relative duration-200 rounded-[10px] md:mx-[10px] my-[10px] cursor-pointer border border-gray-800 flex flex-col justify-between items-start" :class="{'bg-gray-800': trajet.prix_reduit == 0}">
                                <h1 :class="{'text-gray-400': trajet.prix_reduit == trajet.prix, 'text-gray-600': trajet.prix_reduit != trajet.prix}" class="text-xl font-bold duration-200">
                                    <span :class="{'hidden': trajet.prix_reduit != trajet.prix}">{{ trajet.prix }}</span>
                                    <span :class="{'hidden': trajet.prix_reduit == trajet.prix}">{{ trajet.prix_reduit }}</span>
                                    <span class="text-[9px]">FCFA</span>
                                </h1>
                                <p :class="{'text-gray-200': trajet.prix_reduit == 0, 'text-gray-500': trajet.prix_reduit != 0}" class="flex flex-col text-[10px] font-semibold duration-200">
                                    <span>{{ trajet.depart }}</span>
                                    <i class="fa-solid fa-exchange-alt"></i>
                                    <span>{{ trajet.destination }}</span>
                                </p>
                                <div v-if="trajet.action" class="absolute top-0 w-full h-full bg-black/70 rounded-[10px] left-0 flex justify-center items-center">
                                    <button @click="toggleModifie(trajet)" class="w-[30px] h-[30px] rounded-[5px] bg-white m-[10px] flex justify-center items-center text-gray-800 font-bold text-xl">
                                        <i class="fa-solid fa-pen-alt"></i>
                                    </button>
                                    <button @click="deleteTrajet(trajet._id)" class="w-[30px] h-[30px] rounded-[5px] bg-white m-[10px] flex justify-center items-center text-gray-800 font-bold text-xl">
                                        <i class="fa-solid fa-trash-alt"></i>
                                    </button>
                                </div>
                                <div :class="{'translate-x-0': isModifie, 'translate-x-[120%]': !isModifie}" class="md:w-[350px] duration-300 w-[calc(100vw-40px)] h-[calc(100vh-110px)] p-[20px] fixed bg-white shadow-6xl z-20 top-[90px] right-[20px] rounded-[10px]">
                                    <div class="w-full relative flex justify-between items-center">
                                        <h1 class="text-sm font-bold text-gray-800">Modifier le trajet</h1>
                                        <div @click="fermerModifie()" class="w-[30px] h-[30px] cursor-pointer rounded-[50%] flex justify-center items-center bg-gray-200 text-sm font-bold text-gray-800">
                                            <i class="fa-solid fa-times"></i>
                                        </div>
                                    </div>
                                    <form id="parent" @submit.prevent="modifieTrajet" class="w-full relative my-[20px] overflow-auto h-[calc(100%-60px)]">
                                        <div class="my-[10px] w-full relative">
                                            <label for="depart" class="text-xs font-bold text-gray-500">Départ</label>
                                            <input type="text" v-model="depart" id="depart" required class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                                        </div>
                                        <div class="my-[10px] w-full relative">
                                            <label for="destination" class="text-xs font-bold text-gray-500">Destination</label>
                                            <input type="text" v-model="destination" required id="destination" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                                        </div>
                                        <div class="my-[10px] w-full relative">
                                            <label for="prix" class="text-xs font-bold text-gray-500">Prix</label>
                                            <input type="text" v-model="prix" id="prix" required class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                                        </div>
                                        <div class="my-[10px] w-full relative">
                                            <label for="prix_reduit" class="text-xs font-bold text-gray-500">Prix réduit</label>
                                            <input type="text" v-model="prix_reduit" id="prix_reduit" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                                        </div>
                                        <div class="my-[10px] w-full relative">
                                            <label for="heureDepart" class="text-xs font-bold text-gray-500">Heure de départ</label>
                                            <input type="time" v-model="heureDepart" required id="heureDepart" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                                        </div>
                                        <div class="my-[10px] w-full relative">
                                            <label for="duree" class="text-xs font-bold text-gray-500">Estimation de la durée</label>
                                            <input type="number" v-model="duree" id="duree" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                                        </div>
                                        <div class="my-[10px] w-full relative">
                                            <label for="duree" class="text-xs font-bold text-gray-500">Nombre de place</label>
                                            <input type="number" v-model="places" id="duree" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                                        </div>
                                        <button type="submit" class="bg-gray-800 mt-[15px] w-full text-sm font-bold text-white px-4 py-2 rounded-[5px]">Modifier le trajet</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full relative flex justify-center items-center p-[20px] md:flex-row flex-col-reverse">
                    <div class="w-full md:h-[410px] h-auto mx-[10px] my-[10px] bg-white overflow-hidden shadow-4xl rounded-[10px]">
                        <div class="h-[60px] relative my-[10px] w-full bg-white shadow-4xl rounded-[10px] flex flex-col justify-center items-center md:p-[20px]">
                            <div class="absolute z-50 top-0 left-0 p-[20px] pb-0 w-full flex justify-between items-center">
                                <h1 class="text-xs font-bold text-gray-800">Liste des trajets réservé</h1>
                                <button class="px-[10px] py-[2.5px] rounded-[5px] text-[8px] text-white bg-gray-800 font-bold">{{ reservations.length }}</button>
                            </div>
                        </div>
                        <ul class="w-full flex flex-col justify-center items-center relative">
                            <li class="w-full relative bg-gray-200 md:p-[10px] p-[5px] flex justify-center items-center">
                                <div class="text-gray-800 font-bold text-sm w-[60px] min-w-[60px] p-[5px] hidden md:flex">ID</div>
                                <div class="text-gray-800 md:font-bold md:text-sm text-xs font-semibold w-full p-[5px]">Nom</div>
                                <div class="text-gray-800 md:font-bold md:text-sm text-xs font-semibold md:w-[90px] md:min-w-[90px] min-w-[75px] w-[75px] p-[5px]">Départ</div>
                                <div class="text-gray-800 md:font-bold md:text-sm text-xs font-semibold md:w-[90px] md:min-w-[90px] min-w-[75px] w-[75px] p-[5px]">Destination</div>
                                <div class="text-gray-800 md:font-bold md:text-sm text-xs font-semibold md:w-[90px] md:min-w-[90px] hidden md:flex p-[5px]">Date</div>
                                <div class="text-gray-800 md:font-bold md:text-sm text-xs font-semibold md:w-[40px] md:min-w-[40px] flex p-[5px]">Act</div>
                            </li>
                            <div id="parent" class="w-full md:h-[300px] h-auto relative flex flex-col overflow-y-auto">
                                <li id="li_two" v-for="(reservation, index) in reservations" :key="index" class="w-full hiverless_pere cursor-pointer relative md:p-[10px] p-[5px] flex justify-center items-center">
                                    <div class="text-gray-500 font-bold text-sm w-[60px] min-w-[60px] p-[5px] hidden md:flex">#{{ reservation.idReservation }}</div>
                                    <div class="text-gray-500 md:font-bold md:text-sm text-xs font-semibold w-full p-[5px] flex justify-start items-center">
                                        <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center items-center relative border border-white mr-[5px] md:mr-[10px]">
                                            <img src="../assets/images/voyage1.jpg" alt="" class="w-full h-full absolute rounded-full">
                                        </div>
                                        {{ reservation.prenoms }} {{ reservation.nom }}
                                    </div>
                                    <div class="text-gray-500 md:font-bold md:text-sm text-xs font-semibold md:w-[90px] md:min-w-[90px] min-w-[75px] w-[75px] p-[5px]">{{ reservation.depart }}</div>
                                    <div class="text-gray-500 md:font-bold md:text-sm text-xs font-semibold md:w-[90px] md:min-w-[90px] min-w-[75px] w-[75px] p-[5px]">{{ reservation.destination }}</div>
                                    <div class="text-gray-500 md:font-bold md:text-sm text-xs font-semibold md:w-[90px] md:min-w-[90px] hidden md:flex p-[5px]">{{ reservation.date }}</div>
                                    <div class="text-gray-500 md:font-bold md:text-sm text-xs font-semibold md:w-[40px] md:min-w-[40px] flex p-[5px]"><div class="w-[30px] h-[30px] rounded-[50%]" :class="{'bg-red-600': !reservation.isActive, 'bg-green-600': reservation.isActive}"></div></div>
                                    
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div :class="{'translate-x-0': isAjout, 'translate-x-[120%]': !isAjout}" class="md:w-[350px] duration-300 w-[calc(100vw-40px)] h-[calc(100vh-110px)] p-[20px] fixed bg-white shadow-6xl z-20 top-[90px] right-[20px] rounded-[10px]">
                    <div class="w-full relative flex justify-between items-center">
                        <h1 class="text-sm font-bold text-gray-800">Ajouter un trajet</h1>
                        <div @click="toggleAjout()" class="w-[30px] h-[30px] cursor-pointer rounded-[50%] flex justify-center items-center bg-gray-200 text-sm font-bold text-gray-800">
                            <i class="fa-solid fa-times"></i>
                        </div>
                    </div>
                    <form id="parent" @submit.prevent="submitForm" class="w-full relative my-[20px] overflow-auto h-[calc(100%-60px)]">
                        <div class="my-[10px] w-full relative">
                            <label for="depart" class="text-xs font-bold text-gray-500">Départ</label>
                            <input type="text" v-model="depart" id="depart" required class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="destination" class="text-xs font-bold text-gray-500">Destination</label>
                            <input type="text" v-model="destination" required id="destination" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="prix" class="text-xs font-bold text-gray-500">Prix</label>
                            <input type="text" v-model="prix" id="prix" required class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="prix_reduit" class="text-xs font-bold text-gray-500">Prix réduit</label>
                            <input type="text" v-model="prix_reduit" id="prix_reduit" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="heureDepart" class="text-xs font-bold text-gray-500">Heure de départ</label>
                            <input type="time" v-model="heureDepart" required id="heureDepart" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="duree" class="text-xs font-bold text-gray-500">Estimation de la durée</label>
                            <input type="number" v-model="duree" id="duree" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="duree" class="text-xs font-bold text-gray-500">Nombre de place</label>
                            <input type="number" v-model="places" id="duree" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <button type="submit" class="bg-gray-800 mt-[15px] w-full text-sm font-bold text-white px-4 py-2 rounded-[5px]">Ajouter le trajet</button>
                    </form>
                </div>
            </section>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import LineChart from "@/components/LineChart.vue"
import { ref, onMounted } from 'vue'
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';

const depart = ref('');
const destination = ref('');
const prix = ref('');
const prix_reduit = ref('');
const heureDepart = ref('');
const duree = ref('');
const places = ref('')
const selectedTrajet = ref(null); 
const action = ref(false)

const SuccessMessage = ref('')
const ErrorMessage = ref('')
const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      SuccessMessage.value = ''; 
    }, 3000);
};
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      ErrorMessage.value = ''; 
    }, 3000);
};

const authStore = useAuthStore();
const user = computed(() => authStore.user);
import axios from 'axios';

// State for trajets and error
const trajets = ref([]);
const error = ref(false);
const isAjout = ref(false)
const isModifie = ref(false)

function toggleAjout() {
    isAjout.value = !isAjout.value
}

function fermerModifie() {
    isModifie.value = false
}

const deleteTrajet = async (id) => {
  try {
    await axios.delete(`/api/trajets/${id}`);
    window.location.reload()
  } catch (error) {
    ErrorMessage.value = 'Erreur lors de la suppression du trajet.';
  }
};

const toggleModifie = (trajet) => {
  selectedTrajet.value = trajet;
  depart.value = trajet.depart;
  destination.value = trajet.destination;
  prix.value = trajet.prix;
  prix_reduit.value = trajet.prix_reduit;
  heureDepart.value = trajet.heureDepart;
  duree.value = trajet.duree;
  places.value = trajet.places;
  isModifie.value = !isModifie.value;
};

const modifieTrajet = async () => {
  try {
    const updatedTrajet = {
      depart: depart.value,
      destination: destination.value,
      prix: prix.value,
      prix_reduit: prix_reduit.value,
      heureDepart: heureDepart.value,
      duree: duree.value,
      places: places.value
    };

    const response = await axios.put(`/api/trajets/${selectedTrajet.value._id}`, updatedTrajet);
    console.log('Trajet mis à jour:', response.data);

    // Mettre à jour la liste des trajets
    const index = trajets.value.findIndex(trajet => trajet._id === selectedTrajet.value._id);
    trajets.value[index] = response.data;

    isModifie.value = false;
    selectedTrajet.value = null;
  } catch (error) {
    console.error('Erreur lors de la modification du trajet:', error);
  }
};


// Fetch trajets from the API
const fetchTrajets = async () => {
  error.value = false;
  try {
    const response = await axios.get(`/api/trajets/compagnie/${user.value.idCompte}`);
    trajets.value = response.data.map(trajet => ({ ...trajet, action: false }));
  } catch (err) {
    error.value = true;
  }
};

const fetchReservations = async () => {
  try {
    const response = await axios.get(`/api/reservations/compagnie/${user.value.idCompte}`);
    reservations.value = response.data
  } catch (err) {
    ErrorMessage.value = "Erreur lors de la récupération des réservations";
  }
};

const toggleAction = (trajet) => {
  trajet.action = !trajet.action;
};

const submitForm = async () => {
  try {
    const trajetData = {
      depart: depart.value,
      destination: destination.value,
      prix: parseInt(prix.value),
      prix_reduit: parseInt(prix_reduit.value),
      heureDepart: heureDepart.value,
      duree: duree.value,
      places: places.value,
      idCompagnie: user.value.idCompte,
      nomCompagnie: user.value.nomCompagnie,
      escale: [] // Ajoutez les escales si nécessaire
    };
    const response = await axios.post('/api/trajets', trajetData);
    SuccessMessage.value = 'Trajet ajouté avec succès';
    hideSuccessMessageAfterDelay()
    isAjout.value = false
    fetchTrajets()
  } catch (error) {
    ErrorMessage.value = 'Erreur lors de l\'ajout du trajet';
    hideErrorMessageAfterDelay()
  }
};

// Fetch trajets when the component is mounted
onMounted(() => {
  fetchTrajets();
  fetchReservations()
});

const reservations = ref([])
</script>

<style scoped>
#li_two:nth-child(even) {
    background-color: white;
}
#li_two:nth-child(odd) {
    background-color: rgb(243, 244, 246);
}
.Liste:hover h1 {
    color: #e5e7eb;
}
.Liste:hover p {
    color: #e5e7eb;
}
.Liste:nth-child(even) {
    border-style: dashed;
}
.hiverless_pere:hover .hiverless {
    display: flex;
}
.hiverless_pere .hiverless {
    display: none;
}
</style>
