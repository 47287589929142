<template>
    <main class="bg-gray-800 w-full h-screen relative flex justify-center">
        <HeaderComponent/>
        <section class="w-full h-screen p-[10px]">
            <section id="parent" class="w-full h-full relative rounded-[20px] overflow-y-auto bg-white">
                <div class="p-[15px] fixed bg-white z-[100000] rounded-t-[15px] w-[calc(100%-20px)] top-[10px] md:w-[calc(100%-275px)] border-b border-gray-400 flex justify-between items-center">
                    <h1 class="text-sm font-bold text-gray-700">Mes clients</h1>
                    <div class="flex items-center justify-center">
                        <div class="bg-gray-50 flex justify-center items-center">
                            <div class="w-[35px] h-[35px] rounded-[50%] overflow-hidden flex justify-center items-center relative">
                                <img v-if="user.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mx-[5px]">{{ user.nomCompagnie }}</h1>
                        </div>
                        <div class="w-[40px] h-[40px] ml-[15px] md:hidden"></div>
                    </div>
                </div>
                <div class="flex mt-[70px] w-full relative justify-center items-center flex-col p-[20px]">
                    <div class="w-full relative flex justify-between items-center">
                        <h1 class="text-sm font-bold text-gray-800">Liste des clients</h1>
                        <div class="flex justify-center items-center">
                            <label for="" class="text-sm font-semibold text-gray-400 top-[8px] right-[8px] absolute"><i class="fa-solid fa-search"></i></label>
                            <input type="text" name="" id="" class="p-[8px] border border-gray-400 md:w-[300px] w-[170px] rounded-[10px] pr-[28px] outline-none text-sm placeholder:font-bold" placeholder="Recherchez un nom, mail, numero">
                        </div>
                    </div>
                    <div id="parent" class="w-full mt-[25px] flex flex-col justify-center items-center relative">
                        <ul class="flex w-full flex-col justify-center items-center relative">
                            <li class="w-full bg-gray-800 p-[5px] rounded-t-[10px] shadow-6xl relative flex items-center justify-center">
                                <div class="text-sm font-bold text-white p-[5px] w-[60px] min-w-[60px] hidden md:flex">ID</div>
                                <div class="text-sm font-bold text-white p-[5px] md:w-[20%] md:min-w-[20%] w-[100px] min-w-[100px]">Nom</div>
                                <div class="text-sm font-bold text-white p-[5px] w-full">Email</div>
                                <div class="text-sm font-bold text-white p-[5px] md:w-[115px] md:min-w-[115px] w-[90px] min-w-[90px]">Numéro</div>
                                <div class="text-sm font-bold text-white p-[5px] w-[90px] min-w-[90px] hidden md:flex">Date</div>
                            </li>
                            <li id="linee" v-for="(client, index) in clients" :key="index" class="w-full bg-white p-[10px] border-l border-b border-r border-gray-200 relative flex items-center justify-center">
                                <div class="text-xs font-semibold text-gray-800 p-[5px] w-[60px] min-w-[60px] hidden md:flex">#{{ client.id }}</div>
                                <div class="text-xs font-semibold text-gray-800 p-[5px] md:w-[20%] md:min-w-[20%] w-[70px] min-w-[70px]">{{ client.name }}</div>
                                <div id="parent" class="text-xs font-semibold text-gray-800 p-[5px] overflow-x-auto w-full">{{ client.email }}</div>
                                <div class="text-xs font-semibold text-gray-800 p-[5px] md:w-[115px] md:min-w-[115px] w-[90px] min-w-[90px] flex items-center"><span class="text-xs font-semibold hidden md:flex text-gray-500 mr-[5px]">+229</span> {{ client.phone }}</div>
                                <div class="text-xs font-semibold text-gray-800 p-[5px] w-[90px] min-w-[90px] hidden md:flex">{{ client.date }}</div>
                            </li>
                        </ul>
                        <div class="w-full relative mt-[20px] flex justify-center md:justify-end items-center">
                            <button class="w-[25px] h-[25px] rounded-[8px] m-[5px] bg-gray-800 flex justify-center items-center text-white border border-gray-950 font-bold text-sm">1</button>
                            <button class="w-[25px] h-[25px] rounded-[8px] m-[5px] bg-gray-100 flex justify-center items-center text-gray-800 border border-gray-950 font-bold text-sm">2</button>
                            <button class="w-[25px] h-[25px] rounded-[8px] m-[5px] bg-gray-100 flex justify-center items-center text-gray-800 border border-gray-950 font-bold text-sm">3</button>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import LineChart from "@/components/LineChart.vue"
import { ref } from 'vue'
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const clients = ref([
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
    {
        id: 1234,
        name: "AGOSSA Mickaël",
        email: "agossamickael01@gmail.com",
        phone: "67691860",
        date: "15/07/2024"
    },
])

</script>

<style scoped>
#linee:first-child {
    border-radius: 5px 5px 0px 0px;
    border-top: 1px solid rgb(229 231 235);
}
#linee:last-child {
    border-radius: 0px 0px 10px 10px;
    border-bottom: 1px solid rgb(229 231 235);
}
#li_two:nth-child(even) {
    background-color: white;
}
#li_two:nth-child(odd) {
    background-color: rgb(243, 244, 246);
}
.Liste:hover h1 {
    color: #e5e7eb;
}
.Liste:hover p {
    color: #e5e7eb;
}
.Liste:nth-child(even) {
    border-style: dashed;
}
.hiverless_pere:hover .hiverless {
    display: flex;
}
.hiverless_pere .hiverless {
    display: none;
}
</style>
