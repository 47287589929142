<template>
  <router-view/>
</template>

<style lang="scss">
#parent::-webkit-scrollbar {
  width: 0%;
  height: 0%;
}
</style>
