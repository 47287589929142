<template>
    <main class="bg-gray-800 w-full h-screen relative flex justify-center">
        <HeaderComponent/>
        <section class="w-full h-screen p-[10px]">
            <section id="parent" class="w-full h-full relative rounded-[20px] overflow-y-auto bg-white">
                <div class="p-[15px] fixed bg-white z-[100000] rounded-t-[15px] w-[calc(100%-20px)] top-[10px] md:w-[calc(100%-275px)] border-b border-gray-400 flex justify-between items-center">
                    <h1 class="text-sm font-bold text-gray-700">Nos partenaires</h1>
                    <div class="flex items-center justify-center">
                        <div class="bg-gray-50 flex justify-center items-center">
                            <div class="w-[35px] h-[35px] rounded-[50%] overflow-hidden flex justify-center items-center relative">
                                <img v-if="user.nomCompagnie =='La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie =='Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mx-[5px]">{{ user.nomCompagnie }}</h1>
                        </div>
                        <div class="w-[40px] h-[40px] ml-[15px] md:hidden"></div>
                    </div>
                </div>
                <div class="flex mt-[70px] w-full relative justify-center items-center flex-col p-[20px]">
                    <div class="w-full relative flex justify-between items-center">
                        <h1 class="text-sm font-bold text-gray-800">Liste des compagnies</h1>
                        <button @click="toggleAjout()" class="text-sm font-bold py-[5px] px-[10px] text-gray-800 border border-gray-800 rounded-[5px]">Ajouter</button>
                    </div>
                    <div id="parent" class="w-full flex justify-center items-center relative mt-[25px]">
                        <div class="w-full flex flex-wrap justify-center items-center">
                            <div v-for="(compagnie, index) in compagnies" :key="index" class="w-[300px] md:w-[285px] m-[10px] rounded-[20px] relative p-[20px] shadow-4xl">
                                <div class="w-full relative partie flex justify-between pb-[20px] border-b border-dashed border-gray-800 items-center">
                                    <div class="w-full flex items-center">
                                        <div class="w-[35px] h-[35px] overflow-hidden flex justify-center items-center relative">
                                            <img src="../assets/images/footer.jpg" alt="" class="w-full h-full absolute object-contain">
                                        </div>
                                        <h1 class="text-sm font-bold text-gray-800 mx-[10px]">{{ compagnie.name }}</h1>
                                    </div>
                                    <div class="w-[30px] h-[30px] min-w-[30px] md:hidden cursor-pointer text-sm font-bold text-gray-800 flex justify-center items-center">
                                        <i class="fa-solid fa-bars"></i>
                                    </div>
                                </div>
                                <div class="w-full relative py-[20px]">
                                    <ul class="w-full relative">
                                        <li class="my-[10px] w-full list-disc flex items-start">
                                            <i class="fa-solid fa-circle-chevron-right mr-[5px] text-xs font-bold mt-[2.5px] text-green-600"></i>
                                            <h1 class="text-gray-800 font-bold text-sm">Nom du gérant :</h1>
                                            <p class="text-sm font-semibold text-gray-500 ml-[10px]">{{ compagnie.prenomsGerant }} {{ compagnie.nomGerant }}</p>
                                        </li>
                                        <li class="my-[10px] w-full list-disc flex items-start">
                                            <i class="fa-solid fa-circle-chevron-right mr-[5px] text-xs font-bold mt-[2.5px] text-green-600"></i>
                                            <h1 class="text-gray-800 font-bold text-sm">Email:</h1>
                                            <p class="text-sm font-semibold text-gray-500 ml-[10px]">{{ compagnie.emailGerant }}</p>
                                        </li>
                                        <li class="my-[10px] w-full list-disc flex items-start">
                                            <i class="fa-solid fa-circle-chevron-right mr-[5px] text-xs font-bold mt-[2.5px] text-green-600"></i>
                                            <h1 class="text-gray-800 font-bold text-sm">Numéro :</h1>
                                            <p class="text-sm font-semibold text-gray-500 ml-[10px]"><span class="text-gray-400 text-xs">+229</span> {{ compagnie.numeroGerant }}</p>
                                        </li>
                                        <li class="my-[10px] w-full list-disc flex items-center">
                                            <i class="fa-solid fa-circle-chevron-right mr-[5px] text-xs font-bold mt-[2.5px] text-green-600"></i>
                                            <h1 class="text-gray-800 font-bold text-sm">ID du compte :</h1>
                                            <p class="text-sm font-semibold text-gray-500 ml-[10px] p-[5px] bg-gray-100">{{ compagnie.IdCompte }}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="w-full border-t border-gray-300">
                                    <div class="my-[10px] w-full list-disc flex items-center">
                                        <i class="fa-solid fa-users mr-[5px] text-xs font-bold mt-[2.5px] text-green-600"></i>
                                        <h1 class="text-gray-800 font-bold text-sm">Nombre d'employé :</h1>
                                        <p class="text-sm font-bold text-gray-700 ml-[10px] p-[5px]">{{ compagnie.employees }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="{'translate-x-0': isAjout, 'translate-x-[120%]': !isAjout}" class="md:w-[350px] duration-300 w-[calc(100vw-40px)] h-[calc(100vh-110px)] p-[20px] fixed bg-white shadow-6xl z-20 top-[90px] right-[20px] rounded-[10px]">
                    <div class="w-full relative flex justify-between items-center">
                        <h1 class="text-sm font-bold text-gray-800">Ajouter une Compagnie</h1>
                        <div @click="toggleAjout()" class="w-[30px] h-[30px] cursor-pointer rounded-[50%] flex justify-center items-center bg-gray-200 text-sm font-bold text-gray-800">
                            <i class="fa-solid fa-times"></i>
                        </div>
                    </div>
                    <form id="parent" action="" class="w-full relative my-[20px] overflow-auto h-[calc(100%-60px)]">
                        <div class="my-[10px] w-full relative">
                            <label for="" class="text-xs font-bold text-gray-500">Nom de la compagnie</label>
                            <input type="text" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="" class="text-xs font-bold text-gray-500">Email de la compagnie</label>
                            <input type="text" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="" class="text-xs font-bold text-gray-500">Numéro de la compagnie</label>
                            <input type="text" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="" class="text-xs font-bold text-gray-500">Adress de la compagnie</label>
                            <input type="text" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="" class="text-xs font-bold text-gray-500">Mot de passe</label>
                            <input type="password" class="w-full p-[10px] rounded-[5px] focus:border-green-600 outline-none border text-sm">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <label for="" class="text-xs font-bold text-gray-500">Description</label>
                            <textarea type="text" class="w-full p-[10px] h-[120px] resize-none rounded-[5px] focus:border-green-600 outline-none border text-sm"></textarea>
                        </div>
                    </form>
                </div>
            </section>
        </section>
    </main>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { ref } from 'vue'
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const isAjout = ref(false)

function toggleAjout() {
    isAjout.value = !isAjout.value
}

const compagnies = ref([
    {
        id: 1,
        name: "La poste",
        image: "./img/la-poste.jpg",
        nomGerant: "AGOSSA",
        prenomsGerant: "Mickaël",
        emailGerant: "agossamickael01@gmail.com",
        numeroGerant: 67691860,
        IdCompte: "1245 VG24",
        employees: 3
    },
    {
        id: 1,
        name: "La poste",
        image: "./img/la-poste.jpg",
        nomGerant: "AGOSSA",
        prenomsGerant: "Mickaël",
        emailGerant: "agossamickael01@gmail.com",
        numeroGerant: 67691860,
        IdCompte: "1245 VG24",
        employees: 3
    },
    {
        id: 1,
        name: "La poste",
        image: "./img/la-poste.jpg",
        nomGerant: "AGOSSA",
        prenomsGerant: "Mickaël",
        emailGerant: "agossamickael01@gmail.com",
        numeroGerant: 67691860,
        IdCompte: "1245 VG24",
        employees: 3
    },
    {
        id: 1,
        name: "La poste",
        image: "./img/la-poste.jpg",
        nomGerant: "AGOSSA",
        prenomsGerant: "Mickaël",
        emailGerant: "agossamickael01@gmail.com",
        numeroGerant: 67691860,
        IdCompte: "1245 VG24",
        employees: 3
    },
    {
        id: 1,
        name: "La poste",
        image: "./img/la-poste.jpg",
        nomGerant: "AGOSSA",
        prenomsGerant: "Mickaël",
        emailGerant: "agossamickael01@gmail.com",
        numeroGerant: 67691860,
        IdCompte: "1245 VG24",
        employees: 3
    },
    {
        id: 1,
        name: "La poste",
        image: "./img/la-poste.jpg",
        nomGerant: "AGOSSA",
        prenomsGerant: "Mickaël",
        emailGerant: "agossamickael01@gmail.com",
        numeroGerant: 67691860,
        IdCompte: "1245 VG24",
        employees: 3
    },
])

</script>

<style scoped>
.partie::before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f3f4f6;
    position: absolute;
    bottom: -10px;
    left: -30px;
    z-index: 10;
}
.partie::after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f3f4f6;
    position: absolute;
    bottom: -10px;
    right: -30px;
    z-index: 10;
}
</style>
