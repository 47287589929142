<!-- QRScanner.vue -->
<template>
    <main class="bg-gray-800 w-full h-screen relative flex justify-center">
        <HeaderComponent />
        <section class="w-full h-screen p-[10px]">
            <section id="parent" class="w-full h-full relative rounded-[20px] overflow-y-auto bg-white">
                <div class="p-[15px] fixed bg-white z-[100000] rounded-t-[15px] w-[calc(100%-20px)] top-[10px] md:w-[calc(100%-275px)] border-b border-gray-400 flex justify-between items-center">
                    <h1 class="text-sm font-bold text-gray-700">Nos partenaires</h1>
                    <div class="flex items-center justify-center">
                        <div class="bg-gray-50 flex justify-center items-center">
                            <div class="w-[35px] h-[35px] rounded-[50%] overflow-hidden flex justify-center items-center relative">
                                <img v-if="user.nomCompagnie === 'La poste'" src="../assets/images/la-poste.jpg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie === 'ATT'" src="../assets/images/att.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie === 'Youniti'" src="../assets/images/youniti.jpeg" alt="" class="w-full h-full absolute object-contain">
                                <img v-if="user.nomCompagnie === 'Confort Lines'" src="../assets/images/confort.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mx-[5px]">{{ user.nomCompagnie }}</h1>
                        </div>
                        <div class="w-[40px] h-[40px] ml-[15px] md:hidden"></div>
                    </div>
                </div>
                <div class="flex mt-[70px] w-full relative justify-center items-center flex-col p-[20px]">
                    <div class="w-[200px] h-[200px] relative">
                        <qrcode-stream @detect="onDetect" @decode="onDecode" @init="onInit" :camera="camera"></qrcode-stream>
                        <div v-if="scanning" class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                            <div class="loader"></div>
                        </div>
                    </div>
                    <button class="my-[20px] p-[10px] text-sm font-bold bg-gray-800 text-white rounded-[5px]" @click="openFileDialog">Importer une photo</button>
                    <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" class="hidden" />
                    <div v-if="message" :class="{'bg-green-500': isValid, 'bg-red-500': !isValid}" class="mt-[10px] p-[10px] rounded-[5px] font-bold text-sm text-white">
                        <span v-if="isValid"><i class="fa-solid fa-check mr-[5px]"></i></span>
                        <span v-if="!isValid"><i class="fa-solid fa-times mr-[5px]"></i></span>
                        {{ message }}
                    </div>
                    <audio ref="successSound" src="/sounds/success.wav"></audio>
                    <audio ref="errorSound" src="/sounds/error.mp3"></audio>
                </div>
            </section>
        </section>
    </main>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { QrcodeStream } from 'vue-qrcode-reader';
import jsQR from 'jsqr';
import HeaderComponent from "@/components/HeaderComponent.vue";
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const message = ref('');
const isValid = ref(false);
const fileInput = ref(null);
const successSound = ref(null);
const errorSound = ref(null);
const scanning = ref(false);
const camera = ref('auto'); // Use 'auto' to select the camera automatically
const qrcodeStreamRef = ref(null)

const onDetect = async (detectedObject) => {
    if (!detectedObject[0].rawValue) {
        message.value = 'Code QR non valide ou vide!';
        isValid.value = false;
        errorSound.value.play();
        return;
    }

    try {
        scanning.value = true;
        const response = await axios.post(`/api/reservations/verify/${user.value.idCompte}`, { qrData: detectedObject[0].rawValue });
        if (response.data.valid) {
            message.value = 'Code QR valide!';
            isValid.value = true;
            successSound.value.play();
        } else {
            message.value = 'Code QR invalide!';
            isValid.value = false;
            errorSound.value.play();
        }
    } catch (error) {
        message.value = 'Erreur lors de la vérification du code QR!';
        isValid.value = false;
        errorSound.value.play();
    } finally {
        scanning.value = false;
    }

};

const onDetectFile = async (detectedObject) => {
    if (!detectedObject.contente) {
        message.value = 'Code QR non valide ou vide!';
        isValid.value = false;
        errorSound.value.play();
        return;
    }

    try {
        scanning.value = true;
        const response = await axios.post(`/api/reservations/verify/${user.value.idCompte}`, { qrData: detectedObject.contente });
        if (response.data.valid) {
            message.value = 'Code QR valide!';
            isValid.value = true;
            successSound.value.play();
        } else {
            message.value = 'Code QR invalide!';
            isValid.value = false;
            errorSound.value.play();
        }
    } catch (error) {
        message.value = 'Erreur lors de la vérification du code QR!';
        isValid.value = false;
        errorSound.value.play();
    } finally {
        scanning.value = false;
    }

};

const onInit = (promise) => {
    promise.then(() => {
        console.log('Scanner initialisé avec succès');
    }).catch(error => {
        console.error('Erreur lors de l\'initialisation du scanner:', error);
        message.value = 'Erreur lors de l\'initialisation du scanner!';
        isValid.value = false;
        errorSound.value.play();
    });
};

const openFileDialog = () => {
    fileInput.value.click();
};

const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const qrCode = jsQR(imageData.data, canvas.width, canvas.height);
                if (qrCode) {
                    onDetectFile({ contente: qrCode.data });
                } else {
                    message.value = 'Aucun code QR détecté!';
                    isValid.value = false;
                    errorSound.value.play();
                }
            };
        };
        reader.readAsDataURL(file);
    }
};
</script>

<style scoped>
.text-green-500 {
    color: green;
}
.text-red-500 {
    color: red;
}
.hidden {
    display: none;
}
.loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #000;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
