<template>
    <span class="text-2xl text-gray-800 bg-white z-[1000000] w-[40px] h-[40px] rounded-[5px] fixed top-[25px] right-[15px] justify-center items-center cursor-pointer mx-2 lg:hidden flex" @click="toggleMenu()">
        <div><i class="fa-solid fa-bars ouvrir" :class="{'hidden': isMenuOpen}"></i></div>
        <div><i class="fa-solid fa-xmark fermer" :class="{'hidden': !isMenuOpen}"></i></div>
    </span>
    <section id="parent" :class="{'translate-x-0': isMenuOpen, 'translate-x-[-100%]': !isMenuOpen}" class="w-[320px] md:translate-x-0 duration-300 md:relative fixed z-50 bg-gray-800 md:left-0 left-[10px]  md:h-screen h-[calc(100vh-70px)] top-[70px] md:top-0 overflow-y-auto flex justify-center items-center">
        <div class="w-full h-full py-[50px]">
            <div class="w-full px-[30px] mb-[50px] flex items-center">
                <div class="logo flex items-center text-sm font-bold text-white">
                    <div class="w-[25px] h-[25px] flex justify-center items-center bg-white text-gray-800 mr-[10px]">
                        <i class="fa-solid fa-bus-simple text-sm"></i>
                    </div>
                    <h1>Bénin Ease Travel</h1>
                </div>
            </div>
            <div @click="goToDashboardPage()" class="my-[15px] w-full relative flex items-center px-[30px] cursor-pointer hover:font-bold duration-300 hover:text-white py-[5px] text-sm" :class="{'font-bold text-white': $route.path == '/dashboard', 'text-gray-300': $route.path != '/dashboard'}">
                <div :class="{'hidden': $route.path != '/dashboard'}" class="absolute h-[25px] w-[5px] duration-500 rounded-r-[5px] bg-white left-0 top-[50%] translate-y-[-50%]"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M13 9V3h8v6zM3 13V3h8v10zm10 8V11h8v10zM3 21v-6h8v6z"/></svg>
                Dashboard
            </div>
            <div @click="goToClientsPage()" class="my-[15px] w-full relative flex items-center px-[30px] cursor-pointer hover:font-bold duration-300 hover:text-white py-[5px] text-sm" :class="{'font-bold text-white': $route.path == '/clients', 'text-gray-300': $route.path != '/clients'}">
                <div :class="{'hidden': $route.path != '/clients'}" class="absolute h-[25px] w-[5px] duration-500 rounded-r-[5px] bg-white left-0 top-[50%] translate-y-[-50%]"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16"><path fill="currentColor" d="M8.5 4.5a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0m2.4 7.506c.11.542-.348.994-.9.994H2c-.553 0-1.01-.452-.902-.994a5.002 5.002 0 0 1 9.803 0M14.002 12h-1.59a3 3 0 0 0-.04-.29a6.5 6.5 0 0 0-1.167-2.603a3 3 0 0 1 3.633 1.911c.18.522-.283.982-.836.982M12 8a2 2 0 1 0 0-4a2 2 0 0 0 0 4"/></svg>
                Clients
            </div>
            <div @click="goToReservationPage()" class="my-[15px] w-full relative flex items-center px-[30px] cursor-pointer hover:font-bold duration-300 hover:text-white py-[5px] text-sm" :class="{'font-bold text-white': $route.path == '/reservation', 'text-gray-300': $route.path != '/reservation'}">
                <div :class="{'hidden': $route.path != '/reservation'}" class="absolute h-[25px] w-[5px] duration-500 rounded-r-[5px] bg-white left-0 top-[50%] translate-y-[-50%]"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588T21 6v4.675q0 .425-.288.713t-.712.287t-.712-.288t-.288-.712V10H5v10h5.8q.425 0 .713.288T11.8 21t-.288.713T10.8 22zm13 1q-2.075 0-3.537-1.463T13 18t1.463-3.537T18 13t3.538 1.463T23 18t-1.463 3.538T18 23m1.675-2.625l.7-.7L18.5 17.8V15h-1v3.2z"/></svg>
                Réservations
            </div>
            <div @click="goToScannerPage()" class="my-[15px] w-full relative flex items-center px-[30px] cursor-pointer hover:font-bold duration-300 hover:text-white py-[5px] text-sm" :class="{'font-bold text-white': $route.path == '/scanner-qr', 'text-gray-300': $route.path != '/scanner-qr'}">
                <div :class="{'hidden': $route.path != '/scanner-qr'}" class="absolute h-[25px] w-[5px] duration-500 rounded-r-[5px] bg-white left-0 top-[50%] translate-y-[-50%]"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M9.5 6.5v3h-3v-3zM11 5H5v6h6zm-1.5 9.5v3h-3v-3zM11 13H5v6h6zm6.5-6.5v3h-3v-3zM19 5h-6v6h6zm-6 8h1.5v1.5H13zm1.5 1.5H16V16h-1.5zM16 13h1.5v1.5H16zm-3 3h1.5v1.5H13zm1.5 1.5H16V19h-1.5zM16 16h1.5v1.5H16zm1.5-1.5H19V16h-1.5zm0 3H19V19h-1.5zM22 7h-2V4h-3V2h5zm0 15v-5h-2v3h-3v2zM2 22h5v-2H4v-3H2zM2 2v5h2V4h3V2z"/></svg>
                Scanner
            </div>
            <div @click="goToMessagesPage()" class="my-[15px] w-full relative flex items-center px-[30px] cursor-pointer hover:font-bold duration-300 hover:text-white py-[5px] text-sm" :class="{'font-bold text-white': $route.path == '/messages', 'text-gray-300': $route.path != '/messages'}">
                <div :class="{'hidden': $route.path != '/messages'}" class="absolute h-[25px] w-[5px] duration-500 rounded-r-[5px] bg-white left-0 top-[50%] translate-y-[-50%]"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3c5.5 0 10 3.58 10 8s-4.5 8-10 8c-1.24 0-2.43-.18-3.53-.5C5.55 21 2 21 2 21c2.33-2.33 2.7-3.9 2.75-4.5C3.05 15.07 2 13.13 2 11c0-4.42 4.5-8 10-8m5 9v-2h-2v2zm-4 0v-2h-2v2zm-4 0v-2H7v2z"/></svg>
                Messages
            </div>
            <div v-if="user.isSuper" @click="goToCompagniePage()" class="my-[15px] w-full relative flex items-center px-[30px] cursor-pointer hover:font-bold duration-300 hover:text-white py-[5px] text-sm" :class="{'font-bold text-white': $route.path == '/compagnie', 'text-gray-300': $route.path != '/compagnie'}">
                <div :class="{'hidden': $route.path != '/compagnie'}" class="absolute h-[25px] w-[5px] duration-500 rounded-r-[5px] bg-white left-0 top-[50%] translate-y-[-50%]"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11.792 20.712q-.367 0-.645-.25q-.278-.248-.278-.654q0-.194.085-.43t.254-.405l3.683-3.682l-.552-.552l-3.677 3.682q-.17.17-.383.254q-.213.085-.433.085q-.386 0-.655-.269t-.268-.654q0-.231.094-.451q.095-.22.239-.365l3.683-3.682l-.547-.547L8.71 16.47q-.15.15-.373.245t-.448.094q-.381 0-.652-.271t-.271-.652q0-.22.084-.433t.254-.383l3.452-3.452l-.552-.546l-3.446 3.452q-.144.144-.367.239q-.224.094-.454.094q-.406 0-.665-.259q-.258-.258-.258-.664q0-.22.084-.433q.085-.213.254-.383l4.94-4.94l2.154 2.16q.275.275.621.389q.347.115.702.115q.723 0 1.208-.476t.485-1.217q0-.35-.135-.706q-.135-.355-.421-.642l-2.648-2.648l.98-.98q.33-.324.797-.507q.467-.184.934-.184q.497 0 .97.184q.473.183.807.518L21.03 8.47q.315.316.499.77q.184.453.184 1.005q0 .5-.187.945q-.186.445-.496.755l-8.421 8.427q-.162.162-.38.25q-.216.089-.436.089m-7.594-7.539l-1.035-1.035q-.425-.419-.64-1.007q-.215-.589-.215-1.15q0-.592.192-1.075t.49-.781l3.937-3.942q.323-.323.72-.513q.395-.19.863-.19q.502 0 .92.179q.42.178.766.524l4.164 4.163q.15.15.244.373t.094.423q0 .4-.261.672q-.262.27-.662.27q-.225 0-.433-.081q-.207-.082-.382-.257l-2.674-2.661z"/></svg>
                Compagnies
            </div>
            <div class="absolute bottom-0 w-full py-[50px]">
                <div @click="goToSettingsPage()" class="my-[15px] w-full relative flex items-center px-[30px] cursor-pointer hover:font-bold duration-300 hover:text-white py-[5px] text-sm" :class="{'font-bold text-white': $route.path == '/settings', 'text-gray-300': $route.path != '/settings'}">
                    <div :class="{'hidden': $route.path != '/settings'}" class="absolute h-[25px] w-[5px] duration-500 rounded-r-[5px] bg-white left-0 top-[50%] translate-y-[-50%]"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m10.135 21l-.362-2.892q-.479-.145-1.035-.454q-.557-.31-.947-.664l-2.668 1.135l-1.865-3.25l2.306-1.739q-.045-.27-.073-.558q-.03-.288-.03-.559q0-.252.03-.53q.028-.278.073-.626L3.258 9.126l1.865-3.212L7.771 7.03q.448-.373.97-.673q.52-.3 1.013-.464L10.134 3h3.732l.361 2.912q.575.202 1.016.463t.909.654l2.725-1.115l1.865 3.211l-2.382 1.796q.082.31.092.569t.01.51q0 .233-.02.491q-.019.259-.088.626l2.344 1.758l-1.865 3.25l-2.681-1.154q-.467.393-.94.673t-.985.445L13.866 21zm1.838-6.5q1.046 0 1.773-.727T14.473 12t-.727-1.773t-1.773-.727q-1.052 0-1.776.727T9.473 12t.724 1.773t1.776.727"/></svg>
                    Paramètres
                </div>
                <div @click="handleLogout()" class="my-[15px] w-full relative flex items-center px-[30px] text-gray-300 cursor-pointer hover:font-bold duration-300 hover:text-white py-[5px] text-sm font-semibold">
                    <div class="absolute h-[25px] w-[5px] duration-500 hidden rounded-r-[5px] bg-white left-0 top-[50%] translate-y-[-50%]"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h7v2H5v14h7v2zm11-4l-1.375-1.45l2.55-2.55H9v-2h8.175l-2.55-2.55L16 7l5 5z"/></svg>
                    Se déconnecter
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { computed } from 'vue';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const router = useRouter()

const isMenuOpen = ref(false)

const handleLogout = () => {
  authStore.logout();
  router.push({ name: 'Login' });
};

function toggleMenu() {
    isMenuOpen.value = !isMenuOpen.value
}

function goToDashboardPage() {
    router.push('/dashboard')
}
function goToReservationPage() {
    router.push('/reservation')
}
function goToClientsPage() {
    router.push('/clients')
}
function goToMessagesPage() {
    router.push('/messages')
}
function goToSettingsPage() {
    router.push('/settings')
}
function goToCompagniePage() {
    router.push('/compagnie')
}
function goToScannerPage() {
    router.push('/scanner-qr')
}

</script>

<style scoped>
svg {
    width: 25px;
    margin-right: 10px;
}
</style>